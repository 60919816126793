@use "~@ef-global/web-ui/scss/settings/mixins/breakpoints";

.wrapper {
  grid-column: main;
  width: 100%;
  margin: 64px 0 0;
  position: relative;
  display: flex;
  flex-direction: column;

  @include breakpoints.breakpoint(l) {
    grid-column: inner;
    flex-direction: row;
    gap: 24px;
    margin: 128px 0 0;
  }
}

.coverflowImageGroup {
  width: 220px;
  height: 220px;
  margin: 0 auto;
  position: relative;
  perspective: 200px;

  @include breakpoints.breakpoint(m) {
    width: 260px;
    height: 260px;
  }

  @include breakpoints.breakpoint(l) {
    width: 300px;
    height: 300px;
  }
}

.coverflowImageGroupWrap,
.coverflowContent {
  @include breakpoints.breakpoint(l) {
    flex: 1 0 50%;
  }
}

.coverflowImageGroupWrap {
  display: flex;
  align-items: center;
  @include breakpoints.breakpoint(m down) {
    margin: 64px 0;
  }
}

.coverflowImages {
  height: 100%;
  width: 100%;
  position: absolute;
  transform-style: preserve-3d;

  div {
    transform-style: preserve-3d;
  }
}

.imageSlide {
  aspect-ratio: 1/1;
  overflow: hidden;
  user-select:none;
  backface-visibility: hidden;
  border-radius: 16px;
  background-color: #191919;
  z-index: 0;
  display: flex;
  box-shadow: 0 8px 8px rgba(0,0,0,0.3);
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 220px;
  position: absolute;

  .article-image {
    opacity: 0.5;
  }

  @include breakpoints.breakpoint(m) {
    width: 260px;
    height: 260px;
  }

  @include breakpoints.breakpoint(l) {
    width: 300px;
    height: 300px;
  }
}

.controls {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  position: relative;
  top: 36px;
  z-index: 1;

  @include breakpoints.breakpoint(l) {
    width: 100%;
    position: absolute;
    top: calc(50% - 24px);
    justify-content: space-between;
    left: 0;
  }
}

.active {
  display: block;
}

.inactive {
  display: none;
}

.coverflowContent {
  position: relative;
  min-height: 344px;

  > * {
    max-width: 400px;
  }
}

.contentSlide {
  position: absolute;
}

.controlButtonRound {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  transition: opacity 0.5s ease;
  color: currentColor;

  &.inactive {
    pointer-events: none;
    opacity: 0.7;

    :global .ef-icon {
      opacity: 0.5;
    }
  }
}